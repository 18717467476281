import { Computer, Forum, Inventory, LocalActivity, Loyalty, Person } from "@mui/icons-material"
import { DropShipperTypeId, SupplierTypeId } from "./utils"

const Navigations = [
  {
    label: 'Dashboard',
    icon: <Computer />,
    path: '/'
  },
  {
    label: 'Order Management',
    icon: <Inventory />,
    children: [
      {
        label: 'Orders',
        icon: <Inventory />,
        path: '/order-management/orders'
      },
    ]
  },
  {
    label: 'External Store',
    type: [DropShipperTypeId],
    icon: <LocalActivity />,
    path: '/external-store'
  },
  {
    label: 'Listed Products',
    type: [DropShipperTypeId],
    icon: <LocalActivity />,
    path: '/listed-products'
  },
  {
    label: 'Transactions',
    icon: <LocalActivity />,
    path: '/transactions'
  },
  {
    label: 'Refund and Return',
    icon: <LocalActivity />,
    path: '/refund-return'
  },
  {
    label: 'Membership Plan',
    type: [DropShipperTypeId, SupplierTypeId],
    icon: <Loyalty />,
    children: [
      {
        label: 'Activate Membership',
        icon: <Loyalty />,
        path: '/membership-plan/activate-membership'
      },
      {
        label: 'Update Membership',
        icon: <Loyalty />,
        path: '/membership-plan/update-membership'
      },
    ]
  },
  {
    label: 'Chat',
    icon: <Forum />,
    path: '/chat'
  },
  {
    label: 'Profile',
    icon: <Person />,
    path: '/profile'
  },
]

export default Navigations